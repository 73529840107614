import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/theme/charity';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/Charity/Navbar';
import DrawerSection from 'containers/Charity/DrawerSection';
import BannerSection from 'containers/Wildlife/BannerSection';
import CTA from 'containers/Home/CallToAction';
import Invertebrates from 'containers/Wildlife/invertebrates';
import Birds from 'containers/Wildlife/Birds';
import Mammals from 'containers/Wildlife/Mammals';
import Footer from 'containers/Charity/Footer';

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from 'containers/Charity/charity.style';
import Seo from 'components/seo';

const Index = () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo
          title="Wildlife"
          description="Information about the different species of Wildlife residing in the meadows."
        />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ContentWrapper>
            <BannerSection />
            <Invertebrates />
            <Birds />
            <Mammals />
            <CTA />
            <Footer />
          </ContentWrapper>
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
