import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'common/components/Image';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Card from 'common/components/Card';
import FeatureBlock from 'common/components/FeatureBlock';
import AboutUsSectionWrapper from './aboutUsSection.style';
import { androidArrowForward } from 'react-icons-kit/ionicons/androidArrowForward';
import { Icon } from "react-icons-kit";

import Lapwing from './Images/Lapwing.jpeg';
import GreyWagtail from './Images/GreyWagtail.jpeg';
import SongThrush from './Images/SongThrush.jpeg';

const AboutUsSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
}) => {

  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={
                <Heading
                  content="Birds of Conservation Concern"
                  {...title}
                />
              }
              description={
                <Text
                  content="Six amber and twelve red BCC listed species have been recorded and the site has considerable potential to support additional numbers and species of bird. These include,"
                  {...description}
                />
              }
            />
          </Box>
          <Box {...textArea}>
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Lapwing (BCC Red Rating)" {...featureTitle} />}
            />
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Grey wagtail (BCC Red Rating)" {...featureTitle} />}
            />
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Grasshopper warbler (BCC Red Rating)" {...featureTitle} />}
            />
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Starling (BCC Red Rating)" {...featureTitle} />}
            />
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Fieldfare (BCC Red Rating)" {...featureTitle} />}
            />
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Song Thrush (BCC Red Rating)" {...featureTitle} />}
            />
          </Box>
        </Box>
        <Box className="col" {...col}>
          <Card className="group-gallery">
            <Box className="col2">
              <Image
                src={ GreyWagtail }
                objectFit="contain"
                alt="Grey Wagtail"
              />
            </Box>
            <Box className="col1">
              <Image
                src={ Lapwing }
                objectFit="contain"
                alt="Lapwing"
              />
              <Image
                src={ SongThrush }
                objectFit="contain"
                alt="Song Thrush"
              />
            </Box>
          </Card>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default AboutUsSection;
