import React from 'react';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
} from 'containers/shared/banner/bannerSection.style';

import BannerImage from './banner-image.png';

const BannerSection = () => {
  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <h2>
            Wildlife <br /><small>Lammas Lands</small>
          </h2>
          <p>
            The Lammas Lands support the <strong>habitats of principle importance</strong> (previously known as
            BAP priority habitats) <strong>Coastal and Floodplain Grazing Marsh</strong>. The Natural England
            priority habitat inventory also lists the non-priority habitat Good quality semi-improved
            grassland for the site.
          </p>
          <p>
            A total of <strong>108 species of flowering plants</strong> were recorded from
            the site in 1994, and 227 invertebrates were recorded from the site in 2001.
          </p>
          <p>
            <strong>Six amber and twelve red BCC listed bird species</strong> have been recorded and the site has considerable potential to support additional numbers and species of bird
          </p>
        </TextArea>
        <ImageArea style={{ backgroundImage: `url(${BannerImage})` }} />
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
