import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'common/components/Image';
import Fade from 'react-reveal/Fade';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Card from 'common/components/Card';
import FeatureBlock from 'common/components/FeatureBlock';
import AboutUsSectionWrapper from './aboutUsSection.style';
import { androidArrowForward } from 'react-icons-kit/ionicons/androidArrowForward';
import { Icon } from "react-icons-kit";

import ReedBeetle from './Images/ReedBeetle.jpeg';
import Whirligig from './Images/whirligig.jpeg';
import Paraphotistus from './Images/Paraphotistus.png';

const AboutUsSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/agency/group/group-image1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image2: file(
        relativePath: { eq: "image/agency/group/group-image2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image3: file(
        relativePath: { eq: "image/agency/group/group-image3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      agencyJson {
        aboutus {
          id
          title
          icon
        }
      }
    }
  `);

  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          <Card className="group-gallery">
            <Box className="col1">
              <Image
                src={ ReedBeetle }
                objectFit="contain"
                alt="Feature Image 1"
              />
              <Image
                src={ Whirligig }
                objectFit="contain"
                alt="Feature Image 1"
              />
            </Box>
            <Box className="col2">
              <Image
                src={ Paraphotistus }
                objectFit="contain"
                alt="Feature Image 1"
              />
            </Box>
          </Card>
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={
                <Heading
                  content="Over 227 species of Invertebrates"
                  {...title}
                />
              }
              description={
                <Text
                  content="Surveys have located a number of scarce and local invertebrate species associated with swampy conditions and dead wood. The most protected of these being,"
                  {...description}
                />
              }
            />
          </Box>
          <Box {...textArea}>
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Reed Beetles (vulnerable species of principle importance)" {...featureTitle} />}
            />
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Whirligig beetles (Nationally scarce)" {...featureTitle} />}
            />
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Paraphotistus nigricornis (Nationally scarce)" {...featureTitle} />}
            />
            <FeatureBlock
              icon={<Icon icon={androidArrowForward} />}
              iconPosition="left"
              title={<Heading content="Adelphocaoris ticinensis (Nationally scarce)" {...featureTitle} />}
            />
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default AboutUsSection;
