import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Image from 'common/components/Image';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Card from 'common/components/Card';
import FeatureBlock from 'common/components/FeatureBlock';
import AboutUsSectionWrapper from './aboutUsSection.style';

import Bats from './Images/Bats.jpeg';
import Otter from './Images/Otter.gif';
import RoeDeer from './Images/RoeDeer.jpeg';

const AboutUsSection = ({
  row,
  col,
  title,
  textArea,
}) => {
  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Box className="row" {...row}>
        <Box className="col" {...col}>
          <Card className="group-gallery">
            <Box className="col1">
              <Image
                src={ Bats }
                objectFit="contain"
                alt="Bats"
              />
              <Image
                src={ RoeDeer }
                objectFit="contain"
                alt="Roe Deer"
              />
            </Box>
            <Box className="col2">
              <Image
                src={ Otter }
                objectFit="contain"
                alt="Otter"
              />
            </Box>
          </Card>
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={
                <Heading
                  content="Mammals"
                  {...title}
                />
              }
              description={
                <Fragment>
                  <p>Otter have been recorded
                    from Hell Ditch in recent years and under the previous management plan an Otter holt
                    has been constructed. Given the levels of disturbance by people and dogs, and the use
                    of a path along much of the length of the Wey, otters are probably always going to be
                    shy and nocturnal here.</p>


                  <p>The other large mammal seen in the area is Roe Deer,
                    where similar considerations pertain particularly as regards dogs.</p>

                  <p>There is already a facility for bats in the WWII pill box in Overgone Meadow.</p>
                </Fragment>
              }
            />
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
    pl: '40px',
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '30px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default AboutUsSection;
